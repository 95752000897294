import React from 'react'

import StarsSvg from '@images/stars-icon.svg';

export default function Stars({
    color,
    ...otherProps
}) {
    return <StarsSvg
        fill={color ? color : '#DFF693'}
        {...otherProps}
    />
}
