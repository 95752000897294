import styled, { css } from 'styled-components';
import { theme } from '@theme';

import Stars from '@commons/stars';
import WrButton from '@commons/wr-button';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 900;
    margin: 0 0 10px 0;
    width: 100%;
    text-align: center;

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 15px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 20px;
    }
`

export const StyledImageGalleryContainer = styled.div`
    position: relative;
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px 0;
    margin-bottom: 10px;

    ${theme.mediaQuerys.smUp} {
        max-width: 700px;
        margin-bottom: 15px;
    }

    ${theme.mediaQuerys.mdUp} {
        /* max-width: 900px; */
        max-width: 1000px;
        margin-bottom: 20px;
    }

    img {
        display: block;
        padding: 10px;
       
        ${theme.mediaQuerys.smUp} {
            padding: 5px;
        }
    }
`

export const StyledStarsIcon = styled(Stars)`
    width: 200px;
    position: absolute;
    left: -55px;
    bottom: 0px;
    z-index: -1;

    ${props => props.$isRightAligned && css`
        left: unset;
        top: 0px;
        bottom: unset;
        right: -55px;
    `}
`


export const StyledLeftContainer = styled.div`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 60%;
    }
`

export const StyledRightContainer = styled.div`
    width: 100%;
   

    ${theme.mediaQuerys.smUp} {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 40%;
    }
`

export const StyledTopLeftImage = styled(WrImage)`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        width: 50%;
    }
`

export const StyledTopMiddleImage = styled(WrImage)`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        width: 50%;
    }
`

export const StyledLeftBottomImage = styled(WrImage)`
    width: 100%;
`

export const StyledRightTopImage = styled(WrImage)`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        width: calc(100% / 3);
    }
    
    ${theme.mediaQuerys.mdUp} {
        width: 100%;
        padding-left: 20px;
    }
`

export const StyledRightBottomImage = styled(WrImage)`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
       flex: 1;
       padding-left: 10px;
    }
`

export const StyledButtonsContainer = styled.div`
    /* padding-bottom: 15px; */

    ${theme.mediaQuerys.mdUp} {
        display: flex;
        align-items: center;
    }

    .button-wrapper {
        /* padding-right: 10px; */
        margin-bottom: 10px;

        ${theme.mediaQuerys.mdUp} {
            padding-right: 20px;
        }

        &:last-of-type {
            padding-right: 0%;;
        }
    }
    
` 

export const StyledButton = styled(WrButton)`
    /* margin-bottom: 15px;
    margin-right: 20px; */

    /* ${theme.mediaQuerys.mdUp} {
        margin-right: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    } */
`