import React from 'react'
import { StyledTitle, StyledButton, StyledImageGalleryContainer, StyledButtonsContainer, StyledRightBottomImage, StyledLeftBottomImage, StyledLeftContainer, StyledRightContainer, StyledRightTopImage, StyledTopLeftImage, StyledTopMiddleImage, StyledStarsIcon } from './styles';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

export default function MasonryFiveImageHorizontal({
    module,
}) {
    if(module) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            isOverflowHidden={true}
            data-name="gallery_masonry-five-image-horizontal"
        >
            <StyledContainer>  
                {module.title && 
                    <StyledTitle
                        titleFontOptions ={{ mobile: 'lg', desktop: 'sm' }}
                    > 
                        {module.title}
                    </StyledTitle>
                }
                

                <StyledImageGalleryContainer>
                    {module.enableStars && 
                        <StyledStarsIcon 
                            color={module.starsColor} 
                        />
                    }

                    <StyledLeftContainer>
                        {module.topLeftImage && 
                            <StyledTopLeftImage 
                                imageFile={module.topLeftImage.imageFile}
                                alt={module.topLeftImage.altText}
                            />
                        }
                        {module.topMiddleImage && 
                            <StyledTopMiddleImage 
                                imageFile={module.topMiddleImage.imageFile} 
                                alt={module.topMiddleImage.altText} 
                            />
                        }
                        
                        {module.bottomLeftImage && 
                            <StyledLeftBottomImage 
                                imageFile={module.bottomLeftImage.imageFile} 
                                alt={module.bottomLeftImage.altText} 
                            />
                        }
                    </StyledLeftContainer>

                    <StyledRightContainer>
                        {module.topRightImage && 
                            <StyledRightTopImage 
                                imageFile={module.topRightImage.imageFile} 
                                alt={module.topRightImage.altText} 
                            />
                        }
                        {module.bottomRightImage && 
                            <StyledRightBottomImage 
                                imageFile={module.bottomRightImage.imageFile} 
                                alt={module.bottomRightImage.altText} 
                            />
                        }
                    </StyledRightContainer>

                    {module.enableStars && 
                        <StyledStarsIcon 
                            color={module.starsColor} 
                            $isRightAligned={true} 
                        />
                    }
                </StyledImageGalleryContainer>

                
                {module.buttonLink1 || module.buttonLink2 ?
                    <StyledButtonsContainer>
                        {module.buttonLink1 && 
                            <StyledButton 
                                href={module.buttonLink1.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"
                                buttonWrapperClass="button-wrapper"
                            >
                                {module.buttonLink1.title}
                            </StyledButton>
                        }
                        {module.buttonLink2 && 
                            <StyledButton 
                                href={module.buttonLink2.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"
                                buttonWrapperClass="button-wrapper"
                            >
                                {module.buttonLink2.title}
                            </StyledButton>
                        }
                    </StyledButtonsContainer>
                : null}

            </StyledContainer>       
        </StyledModuleContainer>
    )
    return null;
}


